<template>
  <div class="d-flex flex-column align-center" style="width: 100%; height: 100%; overflow-y: auto">
    <div
      class="flex-grow-1 flex-shrink-0 d-flex flex-column justify-center align-center backgroundImageTestimonialsTop"
      style="width: 100%; height: 40vh"
    >
      <div
        class="d-flex flex-column justify-space-around text-center white--text text-h2 font-weight-medium"
        style="width: 50%; height: 20%; position: relative"
      >Testimonials</div>
    </div>

    <div
      class="d-flex flex-column text-center py-10"
      :style="`width: ${$vuetify.breakpoint.mdAndDown ? '90%' : '60%'}`"
    >
      <div
        class="font-weight-medium pb-10 primary--text"
        :class="$vuetify.breakpoint.smAndDown ? 'text-h4' : 'text-h3'"
      >What Our Farmers Say</div>

      <div class="d-flex flex-column">

        <div
          class="d-flex justify-space-around pb-10"
          :class="$vuetify.breakpoint.mdAndDown ? 'flex-column' : null"
        >
          <template v-for="(testimonial, i) in testimonialsArr">
            <div :key="i + 'A'">
              <iframe
                :width="$vuetify.breakpoint.mdAndDown ? '450' : '360'"
                :height="$vuetify.breakpoint.mdAndDown ? '250' : '202'"
                :src="testimonial.videoUrl"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </template>
        </div>

<!--        <v-carousel-->
<!--          cycle-->
<!--          show-arrows-on-hover-->
<!--          :height="$vuetify.breakpoint.lgAndDown ? $vuetify.breakpoint.lgAndDown ? '200' : '180' : '160'"-->
<!--          hide-delimiters-->
<!--        >-->
<!--          <v-carousel-item-->
<!--            v-for="(testimonial, j) in testimonialsArr"-->
<!--            :key="j + 'A'"-->
<!--            transition="slide-x-transition"-->
<!--          >-->
<!--            <div class="d-flex flex-column align-center" style="height: 100%">-->
<!--              <div-->
<!--                class="pb-7 font-weight-regular"-->
<!--                :class="$vuetify.breakpoint.mdAndDown ? 'subtitle-1' : 'text-h6 '"-->
<!--              ><i>{{ testimonial.paragraph }}</i></div>-->
<!--              <div class="d-flex" style="width: 25%; height: 100%">-->
<!--                <div style="width: 75px">-->
<!--                  <img-->
<!--                    v-if="testimonial.image"-->
<!--                    alt="Image"-->
<!--                    :src="testimonial.image"-->
<!--                  >-->
<!--                </div>-->
<!--                <div class="d-flex flex-column flex-grow-1 justify-center text-left">-->
<!--                  <div class="font-weight-medium subtitle-1" style="width: 100%">{{ testimonial.farmerName }}</div>-->
<!--                  <div class="caption" style="width: 100%">{{ testimonial.farmLocation }}</div>-->
<!--                </div>-->
<!--              </div>-->

<!--            </div>-->
<!--          </v-carousel-item>-->
<!--        </v-carousel>-->

      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      testimonialsArr: [
        {
          videoUrl: 'https://youtube.com/embed/yzsTNnUvETI',
          paragraph: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus ad alias atque beatae cumque eius eum facere fugiat harum inventore iure maiores necessitatibus officia quae quam quia temporibus vel, vero.',
          image: null,
          farmerName: 'Piet',
          farmLocation: 'Tzaneen'
        },
        {
          videoUrl: 'https://youtube.com/embed/exhngusyXXA',
          paragraph: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus ad alias atque beatae cumque eius eum facere fugiat harum inventore iure maiores necessitatibus officia quae quam quia temporibus vel, vero.',
          image: null,
          farmerName: 'Sannie',
          farmLocation: 'Tzaneen'
        },
        {
          videoUrl: 'https://youtube.com/embed/cZ3h_CZsbrg',
          paragraph: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus ad alias atque beatae cumque eius eum facere fugiat harum inventore iure maiores necessitatibus officia quae quam quia temporibus vel, vero.',
          image: null,
          farmerName: 'Kobus',
          farmLocation: 'Tzaneen'
        },
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
.backgroundImageTestimonialsTop {
  background-image: url("@/assets/Testimonials_Top.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}
.backgroundImageTestimonialsTop:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.13);
}
</style>