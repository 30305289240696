<template>
  <div class="d-flex flex-column align-center" style="width: 100%; height: 100%; overflow-y: auto">

    <template v-if="selectedBlog === null">
      <div
        class="py-10"
        :style="`width: ${$vuetify.breakpoint.mdAndDown ? '90%' : '60%'}`"
      >
        <template v-for="(blog, i) in blogArr">
          <div :key="i + 'A'" class="d-flex flex-column">
            <div class="pb-2 text-h5 font-weight-regular">{{ blog.title }}</div>
            <div class="subtitle-2 font-weight-regular">{{ blog.date }}</div>
            <div class="py-5">
              <img
                :style="`width: ${$vuetify.breakpoint.smAndDown ? '100%' : '80%'}`"
                alt="Blog Image"
                :src="blog.image"
              />
            </div>
            <div>{{ blog.previewParagraph }}</div>
            <div class="py-5">
              <v-btn
                text
                small
                @click="selectedBlog = blog"
              >
                <div class="d-flex justify-center align-center primary--text">
                  <div>Read More</div>
                  <v-icon small>mdi-chevron-double-right</v-icon>
                </div>
              </v-btn>
            </div>
            <v-divider class="mb-5"></v-divider>
          </div>
        </template>
      </div>
    </template>

    <template v-else>
      <div
        class="mb-10 pt-5"
        :style="`width: ${$vuetify.breakpoint.mdAndDown ? '90%' : '60%'}`"
      >
        <v-btn
          text
          small
          class="primary--text"
          @click="selectedBlog = null"
        >
          <v-icon small>mdi-chevron-double-left</v-icon>
          Go Back
        </v-btn>

        <div class="py-5">
          <img
            :style="`width: ${$vuetify.breakpoint.smAndDown ? '100%' : '80%'}`"
            alt="Blog Image"
            :src="selectedBlog.image"
          />
        </div>

        <div class="text-h5 font-weight-regular">{{ selectedBlog.title }}</div>
        <div class="subtitle-1">By {{ selectedBlog.author }}</div>

        <div v-html="selectedBlog.fullHtml"></div>
      </div>
    </template>

  </div>
</template>

<script>
export default {
  props: {
    blogArr: {
      type: Array,
      required: true,
      default: () => [],
    },
    currentSelectedBlog: {
      type: Object,
      required: false,
      default: () => null,
    }
  },
  data() {
    return {
      selectedBlog: null,
    }
  },
  watch: {
    currentSelectedBlog: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.selectedBlog = JSON.parse(JSON.stringify(newVal));
      }
    }
  }
}
</script>