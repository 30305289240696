<template>
  <div class="d-flex flex-column align-center" style="width: 100%; height: 100%; overflow-y: auto">
    <div
      class="flex-grow-1 flex-shrink-0 d-flex flex-column justify-center align-center backgroundImageServicesTop"
      style="width: 100%; height: 40vh"
    >
      <div
        class="d-flex flex-column justify-space-around text-center white--text text-h2 font-weight-medium"
        style="width: 50%; height: 20%; position: relative"
      >BioServices</div>
    </div>

    <div class="backgroundImageOne d-flex justify-center align-center py-10" style="width: 100%; height: 100%">
      <div
        class="d-flex flex-column text-center"
        :style="`width: ${$vuetify.breakpoint.mdAndDown ? '90%' : '60%'}`"
      >

        <div
          class="d-flex pt-10"
          :class="$vuetify.breakpoint.smAndDown ? 'flex-column justify-center align-center' : null"
        >
          <div style="width: 40%; height: 40%">
            <img
              style="width: 100%"
              alt="BioServices Logo"
              src="services/BioServices.png"
            >
          </div>
          <div class="flex-grow-1 text-h6 font-weight-regular text-left" style="padding-left: 80px">
            We offer a range of services to support regenerative agriculture practices. Our BioServices team provides soil
            and plant health analysis, consulting services, and monitoring tools to help farmers and growers achieve their
            regenerative agriculture goals.
          </div>
        </div>

        <template v-for="(service, i) in servicesArr">
          <div
            :key="i + 'A'"
            class="d-flex py-16"
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-center justify-center' : null"
          >
            <div
              v-if="i % 2 !== 0 || $vuetify.breakpoint.smAndDown"
              class="slideFromLeft d-flex justify-center align-center"
              :style="`width: ${$vuetify.breakpoint.smAndDown ? $vuetify.breakpoint.smOnly ? '40%' : '50%' : '30%'}`"
            >
              <img
                style="width: 100%"
                alt="Main Image"
                :src="service.mainImage"
              />
            </div>
            <div class="d-flex flex-column text-left" style="width: 70%">
              <div>
                <img
                  :style="`width: ${$vuetify.breakpoint.smAndDown ? '100%' : '50%'}`"
                  alt="Title Image"
                  :src="service.titleImage"
                />
              </div>
              <div class="pb-10">{{ service.paragraph }}</div>
              <div>
                <v-btn
                  text
                  tile
                  small
                  color="secondary"
                  @click="$emit('update:clickedBtnId', 'contactUs')"
                >Contact</v-btn>
              </div>
            </div>
            <div
              v-if="i % 2 === 0 && !$vuetify.breakpoint.smAndDown"
              class="slideFromRight d-flex justify-center align-center"
              style="width: 30%"
            >
              <img
                style="width: 100%"
                alt="Main Image"
                :src="service.mainImage"
              >
            </div>
          </div>
        </template>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {

      servicesArr: [
        {
          titleImage: '/services/BioAnalizeSoil_Text.png',
          mainImage: '/services/BioAnalizeSoil_Main.png',
          paragraph: 'BioAnalize-Soil is a soil health analysis service that helps farmers to understand the unique' +
            'characteristics of their soil. By analyzing soil nutrient levels, microbial activity, and physical properties,' +
            'BioAnalize Soil provides farmers with valuable insights into soil health and potential issues affecting' +
            'crop productivity. Our team of experts uses state-of-the-art equipment and techniques to deliver accurate' +
            'and reliable results, empowering farmers to make informed decisions about soil management.',
        },
        {
          titleImage: '/services/BioAnalizeLeaf_Text.png',
          mainImage: '/services/BioAnalizeLeaf_Main.png',
          paragraph: 'BioAnalize Leaf is a plant health analysis service that helps farmers to optimize their crop' +
            'management practices. By analyzing the nutrient content and stress levels of plant leaves, BioAnalize' +
            'Leaf provides valuable insights into the health of crops and potential issues affecting growth. Our team' +
            'of experts uses cutting-edge technology to deliver accurate and reliable results, empowering farmers to' +
            'make informed decisions about crop management.',
        },
        {
          titleImage: '/services/BioWatch_Text.png',
          mainImage: '/services/BioWatch_Main.png',
          paragraph: 'BioWatch is a monitoring tool that helps farmers to track the health and productivity of their' +
            'soil and crops. By measuring key indicators such as soil moisture, temperature, and nutrient levels,' +
            'BioWatch provides real-time data that can help farmers to optimize their management practices and identify' +
            'potential issues before they impact crop productivity. Our team of experts provides ongoing support to' +
            'ensure that farmers get the most value out of the BioWatch monitoring system.',
        },
        {
          titleImage: '/services/BioConsult_Text.png',
          mainImage: '/services/BioConsult_Main.png',
          paragraph: 'BioConsult is a consulting service that helps farmers to design and implement regenerative' +
            'agriculture practices. Our team of experts within each discipline works closely with farmers to understand' +
            'their unique needs and goals, and develops customized plans that prioritize soil health, biodiversity, and' +
            'economic resilience. We provide ongoing support and guidance to ensure the success of regenerative' +
            'practices and the continued profitability of farms.',
        }
      ]

    }
  },
  methods: {

  },
  mounted() {
    const options = {
      root: null,
      threshold: 0.5,
    };

    const slideFromLeftObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-right')
        }
      });
    }, options);

    const slideFromLeftElements = document.querySelectorAll('.slideFromLeft');
    slideFromLeftElements.forEach(el => slideFromLeftObserver.observe(el));

    const slideFromLRightObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-left')
        }
      });
    }, options);

    const slideFromLRightElements = document.querySelectorAll('.slideFromRight');
    slideFromLRightElements.forEach(el => slideFromLRightObserver.observe(el));

  }
}
</script>

<style>
.backgroundImageOne {
  background-image: url("@/assets/Background_One.png");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.backgroundImageServicesTop {
  background-image: url("@/assets/BioServices_Top.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}
.backgroundImageServicesTop:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.13);
}

.slide-right {-webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;}
@-webkit-keyframes slide-right { 0% {-webkit-transform: translateX(0);transform: translateX(0);} 0% {-webkit-transform: translateX(100px);transform: translateX(100px);} }
@keyframes slide-right { 0% {-webkit-transform: translateX(0);transform: translateX(0);} 0% {-webkit-transform: translateX(100px);transform: translateX(100px);} }

.slide-left {-webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;}
@-webkit-keyframes slide-left { 0% {-webkit-transform: translateX(0);transform: translateX(0);} 0% {-webkit-transform: translateX(-100px);transform: translateX(-100px);} }
@keyframes slide-left { 0% {-webkit-transform: translateX(0);transform: translateX(0);} 0% {-webkit-transform: translateX(-100px);transform: translateX(-100px);} }

.slideFromLeft {}
.slideFromRight {}
</style>