<template>
  <v-app>
    <v-app-bar
      app
      height="80"
      color="white"
    >
      <div v-if="!$vuetify.breakpoint.mdAndDown" class="d-flex justify-center align-center" style="width: 100%; height: 100%">
        <div class="d-flex" style="width: 80%; height: 100%">
          <div class="d-flex align-center" style="width: 18%">
            <img
              style="width: 100%"
              src="./assets/Logo_Full_Light.svg"
              alt="Logo"
            >
          </div>

          <div class="flex-grow-1 d-flex align-center justify-center">
            <template v-for="(btn, i) in appBarBtnsArr">
              <v-hover #default="{ hover }" :key="i + 'A'">
                <div
                  class="px-5"
                  :class="hover || btn.active ? 'accent--text' : 'primary--text'"
                  style="cursor: pointer; font-weight: 500"
                  :style="`font-size: ${$vuetify.breakpoint.lgAndDown ? '24px' : '27px'}`"
                  @click="appBarBtnClicked(btn)"
                >
                  {{ btn.name }}
                  <div :style="`border: 1px solid ${hover || btn.active ? 'var(--v-accent-base)' : 'white'}`"></div>
                </div>
              </v-hover>
            </template>
          </div>

        </div>
      </div>

      <div v-else class="d-flex justify-center align-center" style="width: 100%; height: 100%">
        <div class="d-flex" style="width: 80%; height: 100%">
          <div class="d-flex align-center" style="width: 50%">
            <img
              :style="`width: ${$vuetify.breakpoint.smAndDown ? '100%' : '50%'}`"
              src="./assets/Logo_Full_Light.svg"
              alt="Logo"
            >
          </div>

          <div class="flex-grow-1 d-flex justify-end align-center">
            <v-menu offset-y>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                >
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(btn, i) in appBarBtnsArr"
                  :key="i + 'A'"
                >
                  <div
                    class="px-5"
                    :class="btn.active ? 'accent--text' : 'primary--text'"
                    style="cursor: pointer; font-weight: 500"
                    :style="`font-size: ${$vuetify.breakpoint.lgAndDown ? '24px' : '27px'}`"
                    @click="appBarBtnClicked(btn)"
                  >
                    {{ btn.name }}
                    <div :style="`border: 1px solid ${btn.active ? 'var(--v-accent-base)' : 'white'}`"></div>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>

    </v-app-bar>

    <v-main style="width: 100vw; height: 100vh">
      <div style="overflow-y: auto">
        <AppHome
          v-if="currentActiveBtn === 'home'"
          :blogArr="blogArr"
          :selectedBlog.sync="selectedBlog"
          :clickedBtnId.sync="clickedBtnId"
        ></AppHome>
        <AppAbout v-if="currentActiveBtn === 'about'"></AppAbout>
        <AppBlog
          v-if="currentActiveBtn === 'blog'"
          :blogArr="blogArr"
          :currentSelectedBlog="selectedBlog"
        ></AppBlog>
        <AppTestimonials v-if="currentActiveBtn === 'testimonials'"></AppTestimonials>
        <AppServices
          v-if="currentActiveBtn === 'services'"
          :clickedBtnId.sync="clickedBtnId"
        ></AppServices>
        <AppContactUs v-if="currentActiveBtn === 'contactUs'"></AppContactUs>
      </div>

      <AppFooter></AppFooter>

    </v-main>
  </v-app>
</template>

<script>
import AppHome from "@/components/AppHome.vue";
import AppAbout from "@/components/AppAbout.vue";
import AppFooter from "@/components/AppFooter.vue";
import AppServices from "@/components/AppServices.vue";
import AppContactUs from "@/components/AppContactUs.vue";
import AppTestimonials from "@/components/AppTestimonials.vue";
import AppBlog from "@/components/AppBlog.vue";

export default {
  components: {
    AppFooter,
    AppHome,
    AppAbout,
    AppServices,
    AppContactUs,
    AppTestimonials,
    AppBlog
  },
  data() {
    return {

      currentActiveBtn: 'home',

      appBarBtnsArr: [
        {
          name: 'Home',
          id: 'home',
          active: true,
        },
        {
          name: 'About',
          id: 'about',
          active: false,
        },
        {
          name: 'Blog',
          id: 'blog',
          active: false,
        },
        {
          name: 'Testimonials',
          id: 'testimonials',
          active: false,
        },
        {
          name: 'Services',
          id: 'services',
          active: false,
        },
        {
          name: 'Contact Us',
          id: 'contactUs',
          active: false,
        },
      ],

      selectedBlog: null,
      clickedBtnId: null,

      blogArr: [
        {
          title: 'NUTRITION ON AVOCADO TREES USING PLANTSAP ANALYSIS AS A TOOL',
          image: 'blog/Blog_Post_1.jpg',
          date: 'May 5, 2023',
          previewParagraph: 'Introduction I will start off by explaining that one should always use a Tool' +
            '(product analysis, etc.) within the bigger or broader system.  In this case we will look at our findings' +
            'and the use of Plantsap analysis in the overall nutrition of Avocado trees.  To understand this method we' +
            'should consider the reason for …',
          author: 'Rude Willemse',
          fullHtml: `
          <div>
    <h3 style="padding-top: 20px; font-weight: 400">Introduction</h3>
    <div style="padding-top: 20px">
        I will start off by explaining that one should always use a <strong>Tool</strong> (product analysis, etc.) within the bigger or
        broader system.  In this case we will look at our findings and the use of Plantsap analysis in the overall
        nutrition of Avocado trees.
    </div>
    <div style="padding-top: 20px">To understand this method we should consider the reason for using it, the challenges behind it and the overall outcome and interpretation. </div>
    <h3 style="padding-top: 20px; font-weight: 400">Nutrition on Avocado Trees using PLANTSAP ANALYSIS as a tool</h3>
    <div style="padding-top: 20px">
        The main reason for using Plantsap analysis is to have a better understanding of the current nutrient status
        within the Sap of the leaves and with how the plant is using the nutrients or not. When we look at the overall
        health of the plant it gives us a more realistic view seeing that Plantsap analysis takes into consideration
        photosynthesis, Protein Synthesis and the building of secondary metabolites, as well as how the plant manages
        its energy sinks.
    </div>
    <div style="padding-top: 20px">
        The plant then uses the sugars (Brix) to synthesise these elements.  When we say that Brix is the parameter we
        use to evaluate Plant health we should always consider the current nutrient status within the plant.
        We will only then achieve true high brix, nutrient dense plants which then has a natural defence against Abiotic
        and Biotic stresses which plays a major role within the Avocado industry.
    </div>
    <div style="padding-top: 20px">
        When looking at Avocados we are dealing with a number of challenges, firstly is alternate bearing, Abiotic and
        Biotic stresses and the fact that we have no control of the ever changing climate situation and the list of
        economic challenges that we face.  We have found that by using Plantsap as a <strong>Tool</strong> we are able to better manage
        these challenges by making more accurate and precise decisions.  It also helps us on the road to true sustainability
        when looking at market pressure, the price of input costs and future legislation which is becoming more of a
        reality, especially the term nutrient use efficiency.
    </div>
    <div style="padding-top: 20px">
        <strong>
            Plantsap technology is a precision analytical tool that has been researched over time and can assist us in making the use of essential nutrients much more efficient.
        </strong>
    </div>
    <h3 style="padding-top: 20px; font-weight: 400">What is Plantsap analysis?</h3>
    <div style="padding-top: 20px">
        The biggest difference between a traditional leaf sample and plant sap, is Plantsap analyses the sap of the leaf
        where as a traditional leaf sample analyses the tissue.  The traditional leaf sample provides a sum-total
        nutritional status of the previous 28 days which manifests in the tissue of the leaf. Whereas Plantsap provides
        a real time reflection of the current health and nutritional status of the plant.  Plantsap is unique in the
        sense that information of the plant is provided in real time.  The information comes directly from the plant
        itself, which is most accurate. Which then helps us to achieve our Goal.
    </div>
    <div style="padding-top: 20px">
        The laboratory uses Plantsap from selective leaves and tests for 25 different markers to provide a complete
        oversight with what is happening in real time within the plant.  In evaluating the results it is rather clear
        that we have a broad spectrum of results.
    </div>
    <div style="padding-top: 20px">
        <strong>At Biobrix we have a saying "om te meet is om te weet" in other words to measure is to know.</strong>
    </div>
    <div style="padding-top: 20px">
        Since the 1930’s the problem has always been the interpretation of the results.  Plantsap is the only analytical
        tool available respecting that all plants are dynamic and that plants go through photosynthesis different hormonal
        stages and nutritional need stages, Plantsap then enables us to measure those nutritional needs.  Plantsap can then
        help make nutritional applications, timely, effective and also streamline product selection which makes it cost effective.
    </div>
    <div style="padding-top: 20px">
        <strong>"Our plants are talking best we start listening."</strong>
    </div>
</div>
          `,
        },
        {
          title: 'The Power of Regenerative Agriculture: How It Can Overcome Economic and Climate Challenges',
          image: 'blog/Blog_Post_2.jpg',
          date: 'March 7, 2022',
          previewParagraph: 'Agriculture is facing unprecedented challenges due to economic and climate changes.' +
            'Fertilizer prices have doubled, and input costs keep increasing while markets are under pressure. As the' +
            'world’s population continues to grow, farmers are under increasing pressure to produce more food with' +
            'fewer resources. At the same time, climate change is posing new threats to …',
          author: 'Rude Willemse',
          fullHtml: `
          <div>
    <div style="padding-top: 20px">
        Agriculture is facing unprecedented challenges due to economic and climate changes. Fertilizer prices have
        doubled, and input costs keep increasing while markets are under pressure. As the world’s population continues
        to grow, farmers are under increasing pressure to produce more food with fewer resources. At the same time,
        climate change is posing new threats to agriculture, including drought, flooding, and extreme weather conditions.
        Regenerative agriculture offers innovative solutions that help farmers build sustainable, profitable farms for the future.
    </div>
    <h3 style="padding-top: 20px; font-weight: 400">The Power of Regenerative Agriculture: How It Can Overcome Economic and Climate Challenges</h3>
    <div style="padding-top: 20px">
        Regenerative practices improve soil health and increase crop productivity, leading to higher profits for farmers.
        These practices reduce input costs, provide long-term economic benefits, and help farmers build more resilient farms.
        By adopting regenerative practices, farmers can see up to a 300% return on investment over a five-year period.
        Biobrix, a regenerative agricultural company, offers solutions that help farmers improve soil health and increase
        crop yields while reducing input costs.
    </div>
    <div style="padding-top: 20px">
        Regenerative practices promote carbon sequestration in the soil, reducing the carbon footprint of agriculture.
        By sequestering carbon in the soil, farmers can help mitigate the effects of climate change and reduce their
        impact on the environment. Regenerative agriculture practices are also designed to help farmers adapt to climate
        change by improving soil health and reducing the risk of crop failure due to extreme weather conditions.
    </div>
    <div style="padding-top: 20px">
        Farmers across South Africa have successfully implemented regenerative practices, resulting in increased yields,
        improved soil health, and higher profits with Biobrix’s assistance. For example, an avocado farmer from Limpopo
        had an 80% increase in yield, from 10 tons/ha to 18 tons/ha, with a 50% lower input cost in the first year after
        Biobrix assisted the farmer. There are many more such case studies available.
    </div>
    <h3 style="padding-top: 20px; font-weight: 400">Making Farming Fun Again</h3>
    <div style="padding-top: 20px">
        Some farmers may be hesitant to adopt regenerative practices due to a lack of knowledge or resources. However,
        there are resources available to help farmers overcome these barriers. For instance, Biobrix offers education
        and training for farmers to help them understand the benefits of regenerative practices and how to implement
        them successfully.
    </div>
    <div style="padding-top: 20px">
        Regenerative practices offer a solution to the economic and climate challenges facing agriculture today. By
        improving soil health, increasing crop productivity, and promoting carbon sequestration, regenerative agriculture
        is helping farmers build sustainable, profitable farms for future generations. If you’re a farmer looking to
        build a more resilient farm, consider adopting regenerative practices with the help of companies like Biobrix today.
    </div>
</div>
          `,
        }
      ]

    }
  },
  watch: {
    selectedBlog: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (!newVal)
          return;
        this.appBarBtnClicked(this.appBarBtnsArr.find(btn => btn.id === 'blog'));
      }
    },
    clickedBtnId: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (!newVal)
          return;
        this.appBarBtnClicked(this.appBarBtnsArr.find(btn => btn.id === newVal));
      }
    }
  },
  methods: {
    appBarBtnClicked(clickedBtn) {
      this.appBarBtnsArr.find(btn => btn.active).active = false;
      clickedBtn.active = true;
      this.currentActiveBtn = clickedBtn.id;
      this.clickedBtnId = null;
      this.selectedBlog = null;
    }
  }
};
</script>

<style>

</style>