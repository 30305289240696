import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#5a4412',
                secondary: '#325b1e',
                accent: '#2ba452'
            }
        },
        dark: false
    }
});
