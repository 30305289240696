<template>
  <div class="d-flex flex-column align-center" style="width: 100%; height: 100%; overflow-y: auto">
    <div
      class="flex-grow-1 flex-shrink-0 d-flex flex-column justify-center align-center backgroundImageAboutUsTop"
      style="width: 100%; height: 40vh"
    >
      <div
        class="d-flex flex-column justify-space-around text-center white--text text-h2 font-weight-medium"
        style="width: 50%; height: 20%; position: relative"
      >About Us</div>
    </div>

    <div
      class="d-flex py-10"
      :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : null"
      :style="`width: ${$vuetify.breakpoint.mdAndDown ? '90%' : '60%'}`"
    >
      <div class="slideFromRight d-flex flex-column flex-grow-1 flex-equal">
        <div class="flex-grow-1 d-flex justify-center align-center">
          <img
            src="@/assets/Biobrix_biological.png"
            alt="biobrix Definition"
          >
        </div>
        <div
          class="text-center text-h4 font-weight-medium pt-5"
          :class="$vuetify.breakpoint.smAndDown ? 'pb-5' : null"
        >Established in 2021</div>
      </div>
      <div class="flex-grow-1 flex-equal d-flex flex-column justify-center">
        <div style="font-weight: 700; color: rgb(84, 91, 91)">
          As one of the leaders in Regenerative Agriculture, Biobrix aims at creating healthy soil, capable of producing
          high quality, nutrient dense food and ultimately leading to a more productive farm, <i>the biological way…</i>
        </div>
        <div class="py-10">
          The foundation of high-Brix farming lies in the soil.
          Biobrix develops client specific programs to increase microorganisms levels
          that create fertile and balanced soil that produce crops with high Brix readings.
        </div>
        <strong>Rudie & Johan Co-Founders</strong>
      </div>
    </div>

    <div class="backgroundImageOne d-flex justify-center align-center py-10" style="width: 100%; height: 100%">
      <div
        class="d-flex text-center"
        :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : null"
        :style="`width: ${$vuetify.breakpoint.mdAndDown ? '80%' : '60%'}`"
      >
        <div class="flex-grow-1 flex-equal">
          <div class="text-h3 primary--text font-weight-medium pb-10">Mission</div>
          <div>“Regenerate Agriculture through <strong>BALANCE, INNOVATION, SUSTAINABILITY</strong> and <strong>GREAT SERVICE.</strong>”</div>
        </div>
        <div class="flex-grow-1 flex-equal">
          <div
            class="text-h3 primary--text font-weight-medium pb-10"
            :class="$vuetify.breakpoint.smAndDown ? 'pt-10' : null"
          >Vision</div>
          <div class="pb-10"><strong>Where is Biobrix going?</strong></div>
          <div>
            <div class="d-flex text-left">
              <v-icon large>mdi-circle-small</v-icon>
              <div>Creating nutrient-dense food and plants.</div>
            </div>
            <div class="d-flex text-left">
              <v-icon large>mdi-circle-small</v-icon>
              <div>Achieving sustainability through a balance between chemistry, biology and physics.</div>
            </div>
            <div class="d-flex text-left">
              <v-icon large>mdi-circle-small</v-icon>
              <div>Being recognized as a leader in the Regenerative Agricultural movement.</div>
            </div>
            <div class="d-flex text-left">
              <v-icon large>mdi-circle-small</v-icon>
              <div>To be recognized as a trustworthy, reliable company and bringing innovative, regenerative agricultural solutions and services to our clients.</div>
            </div>
          </div>
        </div>
        <div class="flex-grow-1 flex-equal">
          <div
            class="text-h3 primary--text font-weight-medium pb-10"
            :class="$vuetify.breakpoint.smAndDown ? 'pt-10' : null"
          >Culture</div>
          <div><strong>What are we creating inside the Company?</strong></div>
          <div class="py-7"><strong>Values</strong></div>
          <div class="d-flex flex-column">
            <div>1. Integrity</div>
            <div>2. Ethics</div>
            <div>3. Reliability</div>
            <div>4. Trust</div>
            <div>5. Camaraderie</div>
            <div>6. Professionalism</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  mounted() {
    const options = {
      root: null,
      threshold: 0.5,
    };

    const slideFromLRightObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-left')
        }
      });
    }, options);

    const slideFromLRightElements = document.querySelectorAll('.slideFromRight');
    slideFromLRightElements.forEach(el => slideFromLRightObserver.observe(el));
  }
}
</script>

<style>
.backgroundImageOne {
  background-image: url("@/assets/Background_One.png");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.backgroundImageAboutUsTop {
  background-image: url("@/assets/AboutUs_Top.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}
.backgroundImageAboutUsTop:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.13);
}

.slide-right {-webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;}
@-webkit-keyframes slide-right { 0% {-webkit-transform: translateX(0);transform: translateX(0);} 100% {-webkit-transform: translateX(100px);transform: translateX(100px);} }
@keyframes slide-right { 0% {-webkit-transform: translateX(0);transform: translateX(0);} 100% {-webkit-transform: translateX(100px);transform: translateX(100px);} }

.slideFromRight {}
</style>