<template>
  <div class="d-flex flex-column align-center" style="width: 100%; height: 100%; overflow-y: auto">

    <div
      class="flex-grow-1 flex-shrink-0 d-flex flex-column justify-center align-center backgroundImageContactUsTop"
      style="width: 100%; height: 40vh"
    >
      <div
        class="d-flex flex-column justify-space-around text-center white--text font-weight-medium"
        :class="$vuetify.breakpoint.xsOnly ? 'text-h3' : 'text-h2'"
        style="width: 50%; height: 20%; position: relative"
      >Contact Us</div>
    </div>

    <div class="backgroundImageContactUs d-flex flex-column justify-center align-center py-10" style="width: 100%; height: 100%">

      <div
        class="d-flex flex-column text-center"
        :style="`width: ${$vuetify.breakpoint.mdAndDown ? '90%' : '60%'}`"
      >
        <div class="d-flex flex-column">
          <template v-for="(section, i) in contactUsArr">
            <div
              :key="i + 'A'"
              class="d-flex flex-column"
              style="width: 100%"
            >
              <div class="text-h3 font-weight-medium py-10">{{ section.sectionTitle }}</div>
              <div
                class="d-flex"
                :class="$vuetify.breakpoint.smAndDown ? 'flex-column justify-center align-center' : null"
              >
                <template v-for="(childArr, k) in modifiedChildArr(section.childArr)">
                  <div :key="k + 'A'" class="d-flex flex-grow-1 flex-equal flex-column">
                    <template v-for="(person, j) in childArr">
                      <div
                        :key="j + 'A'"
                        class="d-flex text-left py-5"
                        style="width: 100%"
                      >
                        <div class="d-flex justify-center align-center" style="width: 175px; height: 175px">
                          <div class="d-flex justify-center align-center" style="border: 1px solid black; width: 150px; height: 150px; border-radius: 100px">
                            <div
                              class="d-flex justify-center align-center"
                              style="border: 1px solid black; width: 140px; height: 140px; border-radius: 100px; overflow: hidden"
                            >
                              <img
                                style="height: 100%"
                                :src="person.photo"
                                alt="Contact Us"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="d-flex flex-column flex-grow-1">
                          <div class="text-h4 font-weight-bold">{{ person.name }}</div>
                          <div class="text-h6" style="color: #4e5652">{{ person.position }}</div>
                          <div>{{ person.location }}</div>
                            <div class="flex-grow-1 d-flex flex-column justify-end text-h6 font-weight-regular">
                              <v-hover #default="{ hover }">
                                <div :class="hover ? 'secondary--text' : 'primary--text'">
                                  <v-icon :color="hover ? 'secondary' : 'primary'" small>mdi-phone</v-icon>
                                  {{ person.mobile }}
                                </div>
                              </v-hover>
                              <v-hover #default="{ hover }">
                                <div :class="hover ? 'secondary--text' : 'primary--text'">
                                  <v-icon :class="hover ? 'secondary--text' : 'primary--text'" small>mdi-email-outline</v-icon>
                                  {{ person.email }}
                                </div>
                              </v-hover>
                            </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div
        class="d-flex flex-column text-center"
        :style="`width: ${$vuetify.breakpoint.smAndDown ? $vuetify.breakpoint.xsOnly ? '90%' : '60%' : '40%'}`"
      >
        <div class="text-h3 font-weight-medium py-10">BioServices Team</div>
        <div>
          <img
            style="width: 100%"
            alt="BioServices Team"
            src="contactUs/BioServices Team.png"
          />
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      contactUsArr: [
        {
          sectionTitle: 'Head Office',
          childArr: [
            {
              name: 'Renzie Botha',
              position: 'Depot Manager',
              location: null,
              mobile: '015 000 6131',
              email: 'renzie@biobrix.co.za',
              photo: '/contactUs/Renzie Botha.png',
            },
            {
              name: 'Mandi Du Plessis',
              position: 'Administrator',
              location: null,
              mobile: '015 000 6131',
              email: 'info@biobrix.co.za',
              photo: '/contactUs/Mandi Du Plessis.png',
            },
            {
              name: 'Wally Benn',
              position: 'Data and Operations Manager',
              location: null,
              mobile: '015 000 6131',
              email: 'wally@biobrix.co.za',
              photo: '/contactUs/Wally Benn.png',
            }
          ]
        },
        {
          sectionTitle: 'Directors',
          childArr: [
            {
              name: 'Rudie Willemse',
              position: 'Technical Sales Director',
              location: null,
              mobile: '074 289 3320',
              email: 'rudie@biobrix.co.za',
              photo: '/contactUs/Rudie Willemse.png',
            },
            {
              name: 'Johan Coetzee',
              position: 'Commercial Sales Director',
              location: null,
              mobile: '083 299 8702',
              email: 'johan@biobrix.co.za',
              photo: '/contactUs/Johan Coetzee.png',
            },
            {
              name: 'Koos Smit',
              position: 'Executive Director',
              location: null,
              mobile: '082 466 9884',
              email: 'koos@biobrix.co.za',
              photo: '/contactUs/Koos Smit.png',
            },
            {
              name: 'Nadine Du Plessis',
              position: 'Financial Director',
              location: null,
              mobile: '072 458 5464',
              email: 'nadine@biobrix.co.za',
              photo: '/contactUs/Nadine Du Plessis.png',
            }
          ]
        },
        {
          sectionTitle: 'Sales Team',
          childArr: [
            {
              name: 'Rudie Willemse',
              position: 'Technical Sales Director',
              location: 'Hoedspruit, Letsitele, Tzaneen',
              mobile: '074 289 3320',
              email: 'rudie@biobrix.co.za',
              photo: '/contactUs/Rudie Willemse.png',
            },
            {
              name: 'Johan Coetzee',
              position: 'Commercial Sales Director',
              location: 'Letsitele, Tzaneen',
              mobile: '083 299 8702',
              email: 'johan@biobrix.co.za',
              photo: '/contactUs/Johan Coetzee.png',
            },
            {
              name: 'Joubert de Wet',
              position: 'Sales Consultant',
              location: 'Levubu',
              mobile: '082 884 0306',
              email: 'juba@biobrix.co.za',
              photo: '/contactUs/Joubert de Wet.png',
            },
            {
              name: 'Nicky Smit',
              position: 'Sales Consultant',
              location: 'Gauteng',
              mobile: '082 744 8605',
              email: 'nicky@biobrix.co.za',
              photo: '/contactUs/Nicky Smit.png',
            },
            {
              name: 'Gideon Joubert',
              position: 'Sales Consultant',
              location: 'Polokwane',
              mobile: '082 279 4977',
              email: 'gideon@biobrix.co.za',
              photo: '/contactUs/Gideon Joubert.png',
            },
            {
              name: 'Henru Pieters',
              position: 'Sales Consultant',
              location: 'Barberton, Badplaas',
              mobile: '081 351 3138',
              email: 'henru@biobrix.co.za',
              photo: '/contactUs/Henru Pieters.png',
            },
            {
              name: 'Christian de Wet',
              position: 'Sales Consultant',
              location: 'Nelspruit, Malelane',
              mobile: '064 855 4818',
              email: 'christian@biobrix.co.za',
              photo: '/contactUs/Christian de Wet.png',
            },
          ]
        }
      ]
    }
  },
  methods: {
    modifiedChildArr(arr) {
      const arrCopy = JSON.parse(JSON.stringify(arr));
      const returnArr = [];
      const arrHalf = Math.ceil(arrCopy.length / 2);
      const arrOne = [];
      for (let i = 0; i < arrHalf; i++) {
        arrOne.push(arrCopy.splice(i, 1).pop());
      }
      const remainingLength = arr.length - arrHalf;
      const arrTwo = [];
      for (let i = 0; i < remainingLength; i++) {
        arrTwo.push(arrCopy[i])
      }
      returnArr.push(arrOne, arrTwo);
      return returnArr;
    }
  }
}
</script>

<style scoped>
.backgroundImageContactUsTop {
  position: relative;
  background-size: cover;
  background-image: url('@/assets/ContactUs_Top.jpg')

}
.backgroundImageContactUsTop:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.35);
}
.backgroundImageContactUs {
  background-image: url("@/assets/Background_One.png");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>