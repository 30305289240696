<template>
  <div class="d-flex flex-column justify-center align-center my-5" style="width: 100%">
    <div
      class="d-flex"
      :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : null"
      style="height: 100%"
      :style="`width: ${$vuetify.breakpoint.mdAndDown ? '90%' : '60%'}`"
    >

      <div
        class="flex-grow-1 flex-equal d-flex"
        :class="`${$vuetify.breakpoint.smAndDown ? 'justify-center' : null}`"
      >
        <img
          :style="`width: ${$vuetify.breakpoint.smAndDown ? '50%' : '100%'}`"
          alt="logo"
          src="../assets/Logo_Full_Light.svg"
        >
      </div>
      <div
        class="flex-grow-1 flex-equal d-flex align-center justify-center"
        :class="$vuetify.breakpoint.smAndDown ? 'py-5' : null"
      >
        <template v-for="(link, i) in socialsArr">
          <v-hover :key="i + 'A'" #default="{ hover }">
            <div
              class="d-flex align-center justify-center ml-3"
              style="border-radius: 5px"
              :style="`background-color: ${link.bgColor}; width: ${hover ? '45' : '45'}px; height: ${hover ? '45' : '45'}px`"
            >
              <a :href="link.href" target="_blank">
                <v-icon dark>{{ link.icon }}</v-icon>
              </a>
            </div>
          </v-hover>
        </template>
      </div>
      <div class="flex-grow-1 flex-equal d-flex flex-column justify-center align-center secondary--text">
        <div
          class="font-weight-medium"
          :class="$vuetify.breakpoint.mdAndDown ? 'pb-2 subtitle-1' : 'pb-3 text-h6 '"
        >INFO@BIOBRIX.CO.ZA</div>
        <div
          class="text-h6 font-weight-medium"
          :class="$vuetify.breakpoint.mdAndDown ? 'subtitle-1' : 'text-h6 '"
        >015 000 6131</div>
      </div>

    </div>

    <div
      class="my-10"
      style="border: 1px solid black"
      :style="`width: ${$vuetify.breakpoint.mdAndDown ? '90%' : '60%'}`"
    ></div>

    <div
      class="pb-5 d-flex text-center"
      :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : null"
      :style="`width: ${$vuetify.breakpoint.mdAndDown ? '90%' : '60%'}`"
    >
      <div
        class="subtitle-1 flex-grow-1 flex-equal"
        :class="$vuetify.breakpoint.smAndDown ? 'pb-2' : null"
      >Copyright © 2024 BioBrix</div>
      <div class="subtitle-1 flex-grow-1 flex-equal">Created by Biobrix</div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      socialsArr: [
        {
          icon: 'mdi-facebook',
          href: 'https://www.facebook.com/BiobrixTzaneen',
          bgColor: '#4e69a2'
        },
        {
          icon: 'mdi-instagram',
          href: 'https://www.instagram.com/biobrix1/',
          bgColor: '#3b3b3b'
        },
        {
          icon: 'mdi-youtube',
          href: 'https://www.youtube.com/@biobrix',
          bgColor: '#d23635'
        },
        {
          icon: 'mdi-linkedin',
          href: 'https://www.linkedin.com/company/biobrix/',
          bgColor: '#1984bc'
        },
      ]
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>