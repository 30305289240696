<template>
  <div class="d-flex flex-column align-center" style="width: 100%; height: 100%; overflow-y: auto">

    <div class="d-flex flex-shrink-0" style="width: 100%; height: 100vh; overflow-x: hidden">
      <template v-for="(slide, i) in slidesArr">
        <v-slide-x-reverse-transition :key="i + 'B'" hide-on-leave>
          <div
            v-if="currentSlideIndex === i"
            :key="i + 'A'"
            class="flex-grow-1 flex-shrink-0 d-flex flex-column justify-center align-center sliderImage"
            style="height: 100%; width: 100%"
            :style="`background-image: url(${slide.backgroundImg})`"
          >
            <div
              class="d-flex flex-column justify-space-around text-center white--text"
              style="position: relative"
              :style="`width: ${$vuetify.breakpoint.smAndDown ? '90%' : '50%'}; height: ${$vuetify.breakpoint.smAndDown ? '50%' : '20%'}`"
            >
              <div class="text-h2 font-weight-medium">{{ slide.title }}</div>
              <div class="text-h5 py-7">{{ slide.subtitle }}</div>
<!--              <div>-->
<!--                <v-btn-->
<!--                  tile-->
<!--                  dark-->
<!--                  outlined-->
<!--                >-->
<!--                  {{ slide.btnText }}-->
<!--                </v-btn>-->
<!--              </div>-->
            </div>
          </div>
        </v-slide-x-reverse-transition>
      </template>
    </div>

    <div class="d-flex flex-column align-center backgroundImageOne py-16" style="width: 100%">
      <div
        class="text-center"
        :style="`width: ${$vuetify.breakpoint.smAndDown ? '90%' : '50%'}`"
      >
        <div class="slideBottom text-h6 font-weight-regular">
          As one of the leaders in Regenerative agriculture, Biobrix aims at creating healthy soil,
          capable of producing high quality, nutrient dense food and ultimately leading to
          a more productive farm, <strong><i>the biological way…</i></strong>
        </div>
        <div class="py-10 slideFromRight">
          <img
            :style="`width: ${$vuetify.breakpoint.smAndDown ? '90%' : '60%'}`"
            src="@/assets/Biobrix_definition.png"
            alt="biobrix Definition"
          >
        </div>
        <div class="slideBottom">
          <div class="text-h6 font-weight-regular">
            The foundation of high-Brix farming lies in the soil.
            Biobrix develops client specific programs to increase microorganisms levels
            that create fertile and balanced soil that produce crops with high Brix readings.
          </div>
          <div class="text-h6 font-weight-regular">
            Through <strong>BioSolutions</strong> and <strong>BioServices</strong> we can increase beneficial bacteria,
            fungi and enzymes that stimulates plant growth and promotes soil health.
          </div>
        </div>
        <div class="py-10 slideFromLeft">
          <img
            :style="`width: ${$vuetify.breakpoint.smAndDown ? '90%' : '40%'}`"
            src="@/assets/RenewAg.png"
            alt="RenewAg"
          >
        </div>
        <div class="slideBottom">
          <div class="text-h6 font-weight-regular">
            Biobrix’s new concept of RenewAg is to develop a new system which takes on
            a NEW approach to agriculture and its traditional ways and methods.
          </div>
          <div class=" text-h6 font-weight-regular">
            The focus is to achieve true sustainability by implementing all the different disciplines of Regenerative
            and Eco-Agriculture and developing a practical, economic and RENEWED system.
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex-grow-1 flex-shrink-0 d-flex flex-column justify-center align-center backgroundImageTwo"
      style="width: 100%; height: 40vh"
    >
      <div
        class="text-center white--text"
        style="position: relative"
        :style="`width: ${$vuetify.breakpoint.smAndDown ? '90%' : '50%'}`"
      >
        <div class="text-h2 font-weight-medium"><i>Don't Guess Test</i></div>
        <div class="py-7 text-h6">With our BioSERVICES you can make data driven decisions</div>
        <div>
          <v-btn
            tile
            dark
            outlined
            style="width: 190px; height: 45px"
            @click="$emit('update:clickedBtnId', 'services')"
          >
            <div style="font-size: 23px">BioServices</div>
          </v-btn>
        </div>
      </div>
    </div>

    <div class="text-center py-10" style="width: 100%">
      <div class="text-h2 pb-10 font-weight-medium primary--text">Testimonials</div>
      <iframe
        :width="$vuetify.breakpoint.mdAndDown ? '450' : '1120'"
        :height="$vuetify.breakpoint.mdAndDown ? '250' : '630'"
        src="https://www.youtube.com/embed/yzsTNnUvETI"
        title="CP Minnaar Dirk Getuienis"
        style="border: none"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>

    <div class="d-flex flex-column align-center backgroundImageOne py-16" style="width: 100%">
      <div
        class="text-center"
        :style="`width: ${$vuetify.breakpoint.mdAndDown ? '90%' : '60%'}`"
      >
        <div class="text-h2 font-weight-medium primary--text pb-10">Latest Articles</div>
        <div
          class="d-flex"
          :class="$vuetify.breakpoint.smAndDown ? 'flex-column justify-center align-center' : 'justify-space-around'"
        >
          <template v-for="(blog, i) in blogArr">
            <v-hover v-if="i < 3" :key="i + 'A'" #default="{ hover }">
              <div
                class="d-flex flex-column"
                :class="$vuetify.breakpoint.smAndDown ? 'pb-9' : null"
                :style="`width: ${$vuetify.breakpoint.smAndDown ? '90%' : '32%'}`"
              >
                <v-card
                  :elevation="hover ? '10' : '5'"
                  class="d-flex flex-column justify-center align-center"
                >
                  <img
                    style="width: 100%"
                    alt="Blog Image"
                    :src="blog.image"
                  />
                  <div class="text-left py-16" style="width: 80%">
                    <div class="text-h6 font-weight-bold secondary--text">{{ blog.title }}</div>
                    <div class="pt-10 pb-5 font-weight-regular">{{ blog.previewParagraph }}</div>
                    <div class="flex-grow-1">
                      <v-btn
                        text
                        small
                        color="secondary"
                        @click="$emit('update:selectedBlog', blog)"
                      >
                        <div class="d-flex justify-center align-center">
                          <div>Read More</div>
                          <v-icon small>mdi-chevron-double-right</v-icon>
                        </div>
                      </v-btn>
                    </div>
                  </div>
                  <v-divider style="width: 100%"></v-divider>
                  <div class="text-left caption py-2 font-weight-light" style="width: 80%">{{ blog.date }}</div>
                </v-card>
              </div>
            </v-hover>
          </template>
        </div>
      </div>
    </div>

    <div
      class="text-center py-10"
      :style="`width: ${$vuetify.breakpoint.smAndDown ? '80%' : '60%'}`"
    >
      <div class="text-h2 font-weight-medium primary--text pb-10">Our Partners</div>
      <v-carousel
        ref="carouselRef"
        cycle
        show-arrows-on-hover
        height="180"
        hide-delimiters
      >
        <v-carousel-item
          v-for="(childArr, i) in modifiedOurPartnersArr"
          :key="i + 'A'"
          transition="slide-x-transition"
        >
          <div class="d-flex justify-space-between">
            <template v-for="(partner, k) in childArr">
              <div
                :key="k + 'A'"
                class="d-flex justify-center align-center"
                style="background-size: cover"
                :style="`background-image: url(${partner.logo}); ${$vuetify.breakpoint.mdAndDown ? 'width: 150px; height: 150px' : 'width: 180px; height: 180px'}`"
              ></div>
            </template>
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>

  </div>
</template>

<script>

export default {
  props: {
    blogArr: {
      type: Array,
      required: true,
      default: () => [],
    }
  },
  data() {
    return {

      currentSlideIndex: 0,
      setInterValId: null,

      slidesArr: [
        {
          title: 'Join the Regenerative Movement!',
          subtitle: 'The Biological Way...',
          btnText: 'Start Today',
          backgroundImg: 'slideImages/Slide_One.jpg',
        },
        {
          title: 'Grow Nutrient Dense Food',
          subtitle: 'Listen to your crop',
          btnText: 'Read more',
          backgroundImg: 'slideImages/Slide_Two.png',
        },
        {
          title: 'Making Farming Fun Again',
          subtitle: 'Enjoy the Journey of Discovery...',
          btnText: 'Start today',
          backgroundImg: 'slideImages/Slide_Three.jpg',
        },
        {
          title: 'The Biological Way...',
          subtitle: 'RenewAg',
          btnText: 'Click Here',
          backgroundImg: 'slideImages/Slide_Four.jpg',
        }
      ],
      ourPartnersArr: [
        {
          logo: 'ourPartnersImages/BioCoCrops.png'
        },
        {
          logo: 'ourPartnersImages/Cosmocel.png'
        },
        {
          logo: 'ourPartnersImages/IdWalaSA.png'
        },
        {
          logo: 'ourPartnersImages/MicroBika.png'
        },
        {
          logo: 'ourPartnersImages/TalBorneOrganics.png'
        },
        {
          logo: 'ourPartnersImages/PlantSap.png'
        },
        {
          logo: 'ourPartnersImages/Biodyne.png'
        },
        {
          logo: 'ourPartnersImages/Barenbrug.png'
        },
        {
          logo: 'ourPartnersImages/Vitasoil.png'
        },
      ]
    }
  },
  methods: {

  },
  computed: {
    modifiedOurPartnersArr() {
      const ourPartnersArrCopy = JSON.parse(JSON.stringify(this.ourPartnersArr));
      const mainArr = [];

      let carouselsAmount = 2;
      let numOfPartnersToShow = 5;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          numOfPartnersToShow = 2;
          break;
        case "sm":
          numOfPartnersToShow = 3;
          break;
        case "md":
          numOfPartnersToShow = 4;
          break;
        case "lg":
          numOfPartnersToShow = 5;
          break;
        case "xl":
          numOfPartnersToShow = 5;
          break;
      }

      carouselsAmount = Math.ceil(this.ourPartnersArr.length / numOfPartnersToShow);

      for (let i = 0; i < carouselsAmount; i++) {
        const childArr = [];
        for (let k = 0; k < numOfPartnersToShow; k++) {
          const obj = ourPartnersArrCopy.shift();
          childArr.push(obj ? obj : this.ourPartnersArr[k]);
        }
        mainArr.push(childArr);
      }
      return mainArr;
    }
  },
  created() {
    this.setInterValId = setInterval(() => {
      const index = this.currentSlideIndex;
      if ((index + 1) === this.slidesArr.length)
        this.currentSlideIndex = 0;
      else
        this.currentSlideIndex++;
    }, 4500)
  },
  beforeDestroy() {
    clearInterval(this.setInterValId);
  },
  mounted() {
    const options = {
      root: null,
      threshold: 0.5
    }

    const slideBottomObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-in-bottom')
        }
      });
    }, options);

    const slideBottomElements = document.querySelectorAll('.slideBottom');
    slideBottomElements.forEach(el => slideBottomObserver.observe(el));

    const slideFromLeftObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-right')
        }
      });
    }, options);

    const slideFromLeftElements = document.querySelectorAll('.slideFromLeft');
    slideFromLeftElements.forEach(el => slideFromLeftObserver.observe(el));

    const slideFromLRightObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-left')
        }
      });
    }, options);

    const slideFromLRightElements = document.querySelectorAll('.slideFromRight');
    slideFromLRightElements.forEach(el => slideFromLRightObserver.observe(el));

  }
};
</script>

<style scoped>
.backgroundImageOne {
  background-image: url("@/assets/Background_One.png");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.backgroundImageTwo {
  position: relative;
  background-size: cover;
  background-image: url("@/assets/GuessTest_Background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-top: 200px;
  padding-bottom: 200px;
}
.backgroundImageTwo:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.20);
}
.sliderImage {
  position: relative;
  background-size: cover;
}
.sliderImage:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.35);
}

.slide-in-bottom{-webkit-animation:slide-in-bottom .5s cubic-bezier(.25,.46,.45,.94) both;animation:slide-in-bottom .5s cubic-bezier(.25,.46,.45,.94) both}
@-webkit-keyframes slide-in-bottom{0%{-webkit-transform:translateY(1000px);transform:translateY(1000px);opacity:0}100%{-webkit-transform:translateY(0);transform:translateY(0);opacity:1}}@keyframes slide-in-bottom{0%{-webkit-transform:translateY(1000px);transform:translateY(1000px);opacity:0}100%{-webkit-transform:translateY(0);transform:translateY(0);opacity:1}}

.slide-right {-webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;}
@-webkit-keyframes slide-right { 0% {-webkit-transform: translateX(0);transform: translateX(0);} 0% {-webkit-transform: translateX(100px);transform: translateX(100px);} }
@keyframes slide-right { 0% {-webkit-transform: translateX(0);transform: translateX(0);} 0% {-webkit-transform: translateX(100px);transform: translateX(100px);} }

.slide-left {-webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;}
@-webkit-keyframes slide-left { 0% {-webkit-transform: translateX(0);transform: translateX(0);} 0% {-webkit-transform: translateX(-100px);transform: translateX(-100px);} }
@keyframes slide-left { 0% {-webkit-transform: translateX(0);transform: translateX(0);} 0% {-webkit-transform: translateX(-100px);transform: translateX(-100px);} }

.slideFromLeft {}
.slideFromRight {}
.slideBottom {}

</style>